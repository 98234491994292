import type { NextPage } from "next";
import dynamic from "next/dynamic";
import Image from "next/image";
import { Trans, t } from "@lingui/macro";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import Hero from "@/components/Home/Hero";
import { ArrowNarrowRightIcon } from "@heroicons/react/outline";
import { wrapper } from "@/app/store";
import {
  categoriesApi,
  CategoryApiConstants,
  getCategories,
  getCategoriesChild,
  getHomeFeaturedCategories,
} from "@/services/categories";
import { clientsApi, getClients, getTestimonials } from "@/services/clients";
import { Category } from "@/dto/category";
import Link from "next/link";
import { ListPageResults } from "@/app/dto/requests";
import { PAGE_SIZE_CATEGORIES_HOME } from "@/app/constants/config";
import { Client, Testimonial } from "@/app/dto/clients";
import { getDraftUUID } from "@/app/feature/auth";
import { setUUID } from "@/app/slices/appSlice";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { checkCountry } from "@/utils/i18n";
import ProvidersCTALanding from "@/components/commons/ProvidersCTALanding";
import { useRouter } from "next/router";
import CategoriesCarousel from "@/components/commons/CategoriesCarousel";
import SEOinPage from "@/components/commons/SEOinPage";
import SaleLeaseModal from "@/components/Layout/SaleLeaseModal";

const Product = dynamic(() => import("@/components/commons/Product"));
const Testimony = dynamic(() => import("@/components/commons/Testimony"));
const HomeStatistics = dynamic(() => import("@/components/Home/Statistics"));

interface Props {
  categories: Category[];
  featuredCategories: Category[];
  commonLeases: ListPageResults<Category>;
  commonSearches: ListPageResults<Category>;
  clients: Client[];
  testimonials: Testimonial[];
  lastUpdate: string;
}

const IndexPage: NextPage<Props> = ({
  categories,
  commonLeases,
  commonSearches,
  clients,
  testimonials,
  featuredCategories,
  lastUpdate,
}) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { locale } = router;

  const SEOData = {
    description: t`SEO Index description`,
    keywords: t`SEO Index keywords`,
    h2: t`SEO Index h2`,
    h3: t`SEO Index h3`,
  };

  useEffect(() => {
    const draftUUID = typeof window === "undefined" ? null : getDraftUUID();
    dispatch(setUUID(draftUUID));
  }, null);

  const openGraph = {
    title: "RendaloMaq",
    description: t`Rent, buy or sell equipment`,
    image: `/landing/${locale}-hero.webp`,
  };

  return (
    <div>
      <SEOinPage data={SEOData} openGraph={openGraph} />
      <Hero
        title={t`Rent, buy or sell equipment`}
        subtitle={t`Quote online and start your project today`}
        imageUrl={`/landing/${locale}-hero.webp`}
        imageMobileUrl={`/landing/${locale}-hero-mobile.webp`}
        imageHeight={1080}
        imageWidth={1214}
        imageHeightMobile={680}
        imageWidthMobile={814}
        imageAlt="Una excavadora sale de un teléfono móvil, con la pantalla de cotización de RendaloMaq"
        btnFunction={null}
        btnText={null}
        smallHero={true}
        arrowAnchor="commonly-rented"
      >
        <>
          <CategoriesCarousel categories={categories} />
        </>
      </Hero>
      <section className="max-w-[90%] sm:max-w-7xl mx-auto py-8 sm:px-4 lg:py-4">
        <div className="flex justify-between w-full">
          <h2 className="text-lg font-semibold sm:text-xl">
            <Trans>Most wanted equipment</Trans>
          </h2>
          <Link
            href={{
              pathname: "/catalog/all",
              query: { orderBy: CategoryApiConstants.CATEGORY_TYPE_SEARCH },
            }}
            passHref
          >
            <button
              type="button"
              className="inline-flex items-center min-w-[100px] text-center px-2 py-1 sm:py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md 
            text-gray-700 bg-white hover:bg-gray-50"
            >
              <Trans>See more</Trans>
              <ArrowNarrowRightIcon className="w-6 h-6 ml-2"></ArrowNarrowRightIcon>
            </button>
          </Link>
        </div>
        <div className="relative">
          <Swiper
            slidesPerView={1}
            spaceBetween={16}
            navigation={true}
            pagination={false}
            breakpoints={{
              480: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 20,
                allowTouchMove: false,
              },
            }}
            modules={[Pagination, Navigation]}
            className="mySwiper h-[500px]"
            style={{
              marginLeft: "45px",
              marginRight: "45px",
              position: "unset",
            }}
          >
            {commonSearches?.results.map((product) => (
              <SwiperSlide
                className="flex items-center justify-center"
                key={product.id + "_"}
              >
                <Product category={product} priority={true} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>
      <section className="py-8 mx-auto max-w-7xl sm:px-4 lg:py-4">
        <ProvidersCTALanding
          btnText={t`I want to see the benefits!`}
          buttonFunction={() =>
            router.push(`/${locale}/arrendar-mi-maquinaria`)
          }
        />
      </section>
      <section className="max-w-[90%] sm:max-w-7xl mx-auto py-8 sm:px-4 lg:py-14">
        <h2 className="mb-10 text-xl font-semibold text-center lg:text-3xl sm:text-4xl">
          <Trans>We are experts in machinery</Trans>
        </h2>
        <HomeStatistics>
          <div className="h-full grid grid-cols-3">
            <div
              className="text-center h-full flex align-center justify-center flex-col text-md sm:text-2xl relative 
                              after:absolute after:h-24 after:w-[1px] after:bg-white after:right-0"
            >
              <h4 className="text-xl font-bold sm:text-4xl">+800</h4>
              <p>
                <Trans>clients</Trans>
              </p>
              <p>
                <Trans>have trusted us</Trans>
              </p>
            </div>

            <div
              className="text-center h-full flex align-center justify-center flex-col text-md sm:text-2xl relative 
                              after:absolute after:h-24 after:w-[1px] after:bg-white after:right-0"
            >
              <h4 className="text-xl font-bold sm:text-4xl">+450</h4>
              <p>
                <Trans>suppliers</Trans>
              </p>
              <p>
                <Trans>associated</Trans>
              </p>
            </div>

            <div className="flex flex-col justify-center h-full text-center align-center text-md sm:text-2xl">
              <h4 className="text-xl font-bold sm:text-4xl">+5000</h4>
              <p>
                <Trans>machines</Trans>
              </p>
            </div>
          </div>
        </HomeStatistics>
        <h3 className="mt-24 text-xl font-semibold text-center sm:text-3xl">
          <Trans>Our clients</Trans>
        </h3>
        <div className="flex flex-wrap items-center justify-center mt-10 text-center gap-4">
          {clients?.map((client, index) => (
            <div className="text-center" key={`client_${index}`}>
              <div className="relative grayscale hover:grayscale-0 w-[150px] md:w-[100px] h-16">
                <Image
                  layout="fill"
                  objectFit="contain"
                  src={client.logo}
                  alt={client.name}
                />
              </div>
            </div>
          ))}
        </div>
        <h3 className="mt-24 mb-10 text-xl font-semibold text-center sm:text-3xl">
          <Trans>And what they have said</Trans>
        </h3>
        <div className="max-w-[90%] md:max-w-full mx-auto grid sm:grid-cols-2 gap-4">
          {testimonials?.map((testimonial, index) => (
            <Testimony key={`testimonial_${index}`} testimonial={testimonial} />
          ))}
        </div>
        <input
          hidden
          type="text"
          name="lastUpdate"
          readOnly
          value={lastUpdate}
        />
      </section>
    </div>
  );
};

export const getServerSideProps = wrapper.getServerSideProps(
  (store): any =>
    async (context: any) => {
      console.log("getServerSideProps");
      const { locale, req, locales } = context;

      const lcl = locale ?? "es-CL";
      console.log("checkeando redireccion");

      const shouldRedirect = await checkCountry(req, locales);
      console.log("shouldRedirect", shouldRedirect);

      if (shouldRedirect.redirect) {
        console.log("redirigiendo");
        return {
          props: {
            categories: null,
            commonLeases: null,
            commonSearches: null,
            clients: null,
            testimonials: null,
            featuredCategories: null,
          },
          redirect: {
            permanent: false,
            destination: `/${shouldRedirect.locale}`,
          },
        };
      }

      console.log("comenzando dispatchs");
      const catPayload = {
        locale: lcl,
        parent: null,
        order_by: null,
        page_size: null,
        only_children: false,
      };
      store.dispatch(getCategories.initiate(catPayload));
      store.dispatch(getClients.initiate(lcl));
      store.dispatch(getTestimonials.initiate(lcl));
      store.dispatch(getHomeFeaturedCategories.initiate(lcl));

      const commonLeasesPayload = {
        locale: lcl,
        goPage: {
          order_by: CategoryApiConstants.CATEGORY_TYPE_WON,
          page_size: PAGE_SIZE_CATEGORIES_HOME,
          filters: null,
          parent_ids: null,
        },
      };
      store.dispatch(getCategoriesChild.initiate(commonLeasesPayload));

      const commonSearchesPayload = {
        locale: lcl,
        goPage: {
          order_by: CategoryApiConstants.CATEGORY_TYPE_LEASE,
          page_size: PAGE_SIZE_CATEGORIES_HOME,
          filters: null,
          parent_ids: null,
        },
      };

      store.dispatch(getCategoriesChild.initiate(commonSearchesPayload));

      console.log("getRunningTasks");
      await Promise.all(categoriesApi.util.getRunningOperationPromises());
      await Promise.all(clientsApi.util.getRunningOperationPromises());

      console.log("sacando datos");
      const state = store.getState();
      const { data: clients } = getClients.select(lcl)(state);
      const { data: categories } = getCategories.select(catPayload)(state);
      const { data: testimonials } = getTestimonials.select(lcl)(state);
      const { data: featuredCategories } =
        getHomeFeaturedCategories.select(lcl)(state);
      const { data: commonLeases } =
        getCategoriesChild.select(commonLeasesPayload)(state);
      const { data: commonSearches } = getCategoriesChild.select(
        commonSearchesPayload
      )(state);

      context.res.setHeader(
        "Cache-Control",
        "public, s-maxage=300, stale-while-revalidate=600"
      );

      console.log("retornando");
      return {
        props: {
          categories: categories,
          commonLeases: commonLeases,
          commonSearches: commonSearches,
          clients: clients,
          testimonials: testimonials,
          featuredCategories: featuredCategories,
          lastUpdate: Date.now(),
        }, // will be passed to the page component as props
      };
    }
);

export default IndexPage;
